import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
   CardHeader,
} from "reactstrap";
import moment from "moment";
import Header from "./Header";
 import Api from './Api';
import Group3 from "./Group3.png";
import Group4 from "./Group4.png";
import Group5 from "./Group5.png";
import logo from "./logo-new.png";
import logostic3 from "./logostic3.png";
import Ritakeawayfill from "./ri_takeaway-fill.png";


import "./style.css";
const App = () => {
  const [DocketNumber, setDocketNumber] = useState("");
  const [Docketstate, setDocketstate] = useState("");
  const [docketConsignee, setdocketConsignee] = useState("");

  const [error, seterror] = useState("");
  const [disable, setdisable] = useState(false);
  //let history = useHistory();

  const OnSubmit = async () => {
    if (!DocketNumber == "") {
      Api.get(`/docket/${DocketNumber}/docketstate`)
        .then((res) => {
          if(res?.data){
          setDocketstate(res.data);
           seterror("");
          }
        })
        .catch((err) => {
          seterror(err?.response?.data?.message);
        });
        Api.get(`/docket/getDocketConsigneedetails?DocketNumber=${DocketNumber}`)
        .then((res) => {
          if(res?.data){

          setdocketConsignee(res.data);
          seterror("");
          }
        })
        .catch((err) => {
         // seterror(err.response.data.message);
        });
    } else {
      seterror("Kindly Enter Docket Number");
      setDocketstate("");
    }
    
  };
  let num = Docketstate && Docketstate.length;
  // style={{  
  //   backgroundImage: `url(${logistic})`,
  //   backgroundPosition: 'center',
  //   backgroundSize: 'cover',
  //   backgroundRepeat: 'no-repeat'
  // }}
   return (
    <div >
      {/* <Header /> */}

      {/*  */}
      <Card className="card imageclass"style={{backgroundImage:`url(${logostic3})`}} >
      <img
          src={logo}
          alt="logo"
          height="60px"
          style={{ margin: "auto",marginTop:20,height:90 }}
        />
        {/* <CardHeader className="headercontent">
          Docket Tracking
        </CardHeader> */}
        <CardBody>
          <Form>
            <Row className="justify-content-md-center content uppertextfield">
              <Col lg="4">
                <FormGroup>
                  {/* <Label>Enter Docket Number</Label> */}
                  <Input
                    type="text"
                    value={DocketNumber}
                    onChange={(event) => setDocketNumber(event.target.value)}
                    name="Docket Number"
                    placeholder="Enter Docket Number..."
                  />
                </FormGroup>
                {error && <Label style={{ color: "red" }}>{error}</Label>}
              </Col>
              <Col lg="1" className="classbutton">
                <Button
                  style={{ marginBottom: "40px" }}
                  className="secondarybutton"
                  onClick={OnSubmit}
                >
                  Search
                </Button>
                {/* <Button
                    style={{ marginTop: "28px", marginBottom:"40px", marginLeft:"25px" }}
                    className="text-center p-2 pl-4 pr-4"
                    onClick={(event) => {setDocketNumber(""), setDocketstate(""),seterror("")}}
                  >
                    Clear
                  </Button> */}
              </Col>
            </Row>
          </Form>
          {/* <Row> */}
          {/* {Docketstate &&
              Docketstate.map((item, index) => (
                <Col
                  lg="3"
                  style={{
                    paddingBottom: "20px",
                    alignSelf: "center",
                    margin: "auto",
                  }}
                >
                  <Row>
                  <Col >
                    <Label>
                      {moment(item.createdOn).format("DD-MM-YYYY hh:mm a")}
                    </Label>
                  </Col>
                  
                  <Col>
                 
                    <Label style={item.status==='delivered'?{background:'#ccff99',fontWeight: "bold", padding: "5px", textTransform: "capitalize"}:{background:'#d6e0f5', fontWeight: "bold", padding: "5px", textTransform: "capitalize" }}>
                      {item.status}
                    </Label>
                
                    <Label>
                      {item &&
                        item.SourceWarehouse &&
                        item.SourceWarehouse.address}
                      ,{" "}
                      {item &&
                        item.SourceWarehouse &&
                        item.SourceWarehouse.district &&
                        item.SourceWarehouse.district.DistrictName}
                      ,{" "}
                      {item &&
                        item.SourceWarehouse &&
                        item.SourceWarehouse.state &&
                        item.SourceWarehouse.state.StateName}
                    </Label>
               
                  </Col>
                  </Row>

                  {!(num == index + 1) && (
                    <Col style={{ paddingLeft: "40%" }}>
                      <FeatherIcon icon="arrow-down" size="35" />
                    </Col>
                  )}
                </Col>
              ))} */}

          {Docketstate.length != 0 && (
            <div>
            <Col
              lg="9"
              className="opacityclass"
              style={{
                alignSelf: "center",
                margin: "auto",
              }}
            >
              <Row className="justify-content-md-center">
                {/* <Col
                         
                          xs="3"
                          sm="3"
                          lg="3"
                        >
                        
                        </Col> */}
                <Col className=" trackboxoutline titlecenter classinnercontainer " xs="3" sm="3" lg="3">
                  Status
                </Col>

                <Col className="trackboxoutline titlecenter classinnercontainer" xs="3" sm="3" lg="3">
                  Created On
                </Col>
                <Col className="trackboxoutline titlecenter classinnercontainer" xs="3" sm="3" lg="3">
                  Address
                </Col>
              </Row>
            </Col>
            </div>
          )}
          {Docketstate &&
            Docketstate.map((item, index) => (
              <div  >
              <Col
                lg="9"
                className="opacityclass"
               
                style={{
                  alignSelf: "center",
                  margin: "auto",
                }}
              >
                <Row className="justify-content-md-center ">
                  {/* <Col className="trackboxoutlinesimg text-center"
                         xs="3"
                         sm="3"
                         lg="3">
                        {item.status=="booked" && (
                                         <img
                                    src={bookedlogo}
                                    style={{ width: 90, height: 80 }}
                                  />
                                  )}
                        </Col> */}
                  <Col
                    className="trackboxoutlines p12 titlecenter innerclasscontainer h5"
                    xs="3"
                    sm="3"
                    lg="3"
                
                    style={{textTransform:'capitalize'}}
                  >
                 
                    {item.status == "booked" && (
                      <img src={Group5} style={{ width: 40, height: 40,marginRight:'11%' }} />
                    )}
                     {item.status == "intransit" && (
                      <img src={Group3} style={{ width: 40, height: 40,marginRight:'11%' }} />
                    )}
                     {item.status == "delivered" && (
                      <img src={Group4} style={{ width: 40, height: 40,marginRight:'11%' }} />
                    )}
                   {item.status == "outfordelivery" && (
                      <img src={Ritakeawayfill} style={{ width: 40, height: 40,marginRight:'11%' }} />
                    )}
                    {item.status == "outfordelivery"?"Out For Delivery":item.status}
                  
                  </Col>

                  <Col
                    className="trackboxoutlines  text-center pt-xl-4 h5"
                    xs="4"
                    sm="4"
                    lg="4"
                  >
                     <div style={{}}>
                    {moment(item.createdOn).format("DD-MM-YYYY hh:mm a")}
                    </div>
                  </Col>
              <Col
                    className="trackboxoutlines text-center pt-xl-4 h6"
                    xs="3"
                    sm="3"
                    lg="3"
                  >
                    {item.status == "delivered"&& <div  >
                    {docketConsignee &&
                      docketConsignee?.[0]?.consignee?.address }
                    
                    
                      </div>}
                        {((item.status != "intransit")&&(item.status != "delivered"))&& <div  >
                    {item &&
                      item.SourceWarehouse &&
                      item.SourceWarehouse.address}
                    ,{" "}
                    {item &&
                      item.SourceWarehouse &&
                      item.SourceWarehouse.district &&
                      item.SourceWarehouse.district.DistrictName}
                    ,{" "}
                    {item &&
                      item.SourceWarehouse &&
                      item.SourceWarehouse.state &&
                      item.SourceWarehouse.state.StateName}
                      </div>}
                  </Col>
                </Row>
              </Col>
              </div>
            ))}
        </CardBody>
      </Card>
    </div>
  );
};

export default App;
