import React, { Component } from "react";
 import logo from "./logo-new.png";
 
class NavBar extends Component {
  render() {
    return (
      <div className=" navbar">
        <img
          src={logo}
          alt="logo"
          height="60px"
          style={{ marginRight: "90%" }}
        />
      </div>   
    );
  }
}

export default NavBar;
